.App {
  padding: 10px;
}

.header {
  font-size: 32px;
  margin-bottom: 20px;
}

.colHeader {
  font-size: 24px;
}
